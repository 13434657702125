import React from 'react'

const TryAlly = () => (
  <section className="try row">
    <div className="col-xs-12 col-sm-7 try__text">
      <p className="heading heading--sm hidden-mobile">Try Ally today</p>
      <p className="heading heading--sm visible-only-mobile">
        Try Ally with your team for free
      </p>
      <p className="small hidden-mobile">
        Stage company? Eligible applicants get our Pro products for just $29 a
        month.
      </p>
    </div>
    <div className="col-xs-12 col-sm-4 col-sm-offset-1">
      <a href="https://app.getally.com" className="try__btn btn btn--blue">
        Get Started
      </a>
      <ul className="try__list list list--marks">
        <li>Free 14-day trial</li>
        <li>Easy setup</li>
        <li>No card required</li>
      </ul>
    </div>
  </section>
)

export default TryAlly
