import React from 'react'
import Image from '../../components/Image/Image'

const Companies = ({ className }) => (
  <div className={`companies row ${className}`}>
    <div className="zapier" alt="Zapier" />
    <div className="wistia" alt="Wistia" />
    <div className="grubhub" alt="Grubhub" />
    <div className="wistia" alt="Wistia" />
  </div>
)

export default Companies
